.PreviewButton {
	display: inline-block;
}

.PreviewButtonGroup {
	display: grid;
	padding: 0 0 3rem;
	grid-template-columns: repeat(1, 12rem);
	grid-template-rows: repeat(4, minmax(2rem, auto));
	row-gap: 2rem;
}

@media (min-width: 480px) {
	.PreviewButtonGroup {
		grid-template-columns: repeat(2, 12rem);
		grid-template-rows: repeat(2, minmax(2rem, auto));
	}
}
