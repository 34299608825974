:root {
	--neutral-light-50: #f3f3f3;
	--neutral-light-150: #e0e0e0;
	--neutral-light-300: #bebebe;
	--neutral-light-450: #9b9b9b;
	--neutral-light-600: #7c7c7c;
	--neutral-light-750: #5c5c5c;
	--neutral-light-900: #353535;
	--neutral-dark-50: #e8e8e8;
	--neutral-dark-150: #d1d1d1;
	--neutral-dark-300: #b6b6b6;
	--neutral-dark-450: #9b9b9b;
	--neutral-dark-600: #777777;
	--neutral-dark-750: #505050;
	--neutral-dark-900: #282828;

	--blue-light-900: #0030ad;
	--blue-light-900-rgb: 0, 48, 173;

	/** bootstrap overrides **/
	--bs-border-color: var(--neutral-light-600);
	--bs-border-width: 1px;
	--bs-link-color-rgb: var(--blue-light-900-rgb);
	--bs-link-color: var(--blue-light-900);
}

body {
	margin: 0;
	font-family: 'Lato', 'Fira Sans', apple-system, BlinkMacSystemFont, 'Segoe UI',
		'Roboto', 'Oxygen', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code,
pre {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

h1 {
	color: var(--neutral-light-600);
}

h2 {
	color: var(--neutral-light-750);
}

/** bootstrap overrides **/
.nav-tabs .nav-link {
	border-color: var(--bs-border-color);
	background-color: var(--neutral-light-50);
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover:not(.active) {
	border-color: var(--neutral-dark-750);
	background-color: var(--neutral-dark-750);
	color: white;
	outline-color: transparent;
	box-shadow: none;
}

.form-check-input:checked {
	background-color: var(--neutral-light-600);
	border-color: var(--neutral-light-600);
}

.form-check-input:focus {
	outline: 3px solid var(--neutral-light-600);
}

.btn-primary {
	--bs-btn-color: var(--neutral-light-900);
	--bs-btn-bg: var(--neutral-light-150);
	--bs-btn-border-color: var(--neutral-light-600);
	--bs-btn-border-width: 2px;
	--bs-btn-hover-color: white;
	--bs-btn-hover-bg: var(--neutral-dark-750);
	--bs-btn-hover-border-color: var(--neutral-dark-750);
	--bs-btn-focus-shadow-rgb: transparent;
	--bs-btn-disabled-border-color: var(--blue-light-900);
}
