.PalettesForm {
	padding-bottom: 2rem;
}

.PalettesForm-standard {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.PalettesForm-standard--label {
	font-size: 1.25rem;
	font-weight: bold;
}

.PalettesForm--base-color {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 0.25rem;
}

.PalettesForm-standard--label,
.PalettesForm-base-color--label {
	font-size: 1.25rem;
	font-weight: bold;
}

.PalettesForm-standard--input {
	margin: 0 0.5rem;
}

.PalettesForm-base-color--input {
	margin-bottom: 0.25rem;
}

.PalettesForm-base-color--value {
	padding: 0 0.5rem;
}

@media (min-width: 576px) {
	.PalettesForm-standard {
		flex-direction: row;
	}

	.PalettesForm--base-color {
		flex-direction: row;
		justify-content: flex-start;
		padding-bottom: 0;
	}

	.PalettesForm-base-color--label {
		margin-right: 0.5rem;
	}
}
