.PreviewButton--label {
	margin-bottom: 0;
	line-height: 1.75;
}

.PreviewButton--button {
	padding: 0.5rem 1rem;
	font-size: 14px;
	font-weight: 700;
	border-style: solid;
	border-width: 2px;
	border-radius: 5px;
}
