.Preview-modes__layout--desktop {
	display: none;
}

.Preview-modes--mode-selector {
	margin: 0 auto;
	padding: 1rem;
	justify-content: center;
}

@media (min-width: 1280px) {
	.Preview-modes__layout--desktop {
		display: flex;
		justify-content: center;
	}

	.Preview-modes__layout--mobile {
		display: none;
	}

	.Preview--mode {
		flex-basis: 50%;
	}
}
