.Palette--sub-palette {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}
.Palette--swatch {
	height: 4rem;
	width: 5rem;
	font-size: 16px;
	font-weight: 700;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@media (min-width: 576px) {
	.Palette--sub-palette {
		grid-template-columns: repeat(6, 1fr);
	}
	.Palette--sub-palette__columns--5 {
		grid-template-columns: repeat(5, 1fr);
	}
}
