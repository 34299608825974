/* font weight */

.PreviewText {
	font-weight: 400;
	line-height: 1.25;
	overflow-wrap: break-word;
}

.PreviewText__weight--bold {
	font-weight: 700;
}

.PreviewText__weight--light {
	font-weight: 300;
}

/* font size */

.PreviewText__size--14 {
	font-size: 14px;
}

.PreviewText__size--16 {
	font-size: 16px;
}

.PreviewText__size--18 {
	font-size: 18px;
}

.PreviewText__size--20 {
	font-size: 20px;
}

.PreviewText__size--24 {
	font-size: 24px;
}

.PreviewText__size--36 {
	font-size: 36px;
}

/* font color */

.PreviewText__color--white {
	color: white;
}

.PreviewText__color--light-50 {
	color: var(--neutral-light-50);
}

.PreviewText__color--light-150 {
	color: var(--neutral-light-150);
}

.PreviewText__color--light-300 {
	color: var(--neutral-light-300);
}

.PreviewText__color--light-450 {
	color: var(--neutral-light-450);
}

.PreviewText__color--light-600 {
	color: var(--neutral-light-600);
}

.PreviewText__color--light-750 {
	color: var(--neutral-light-750);
}

.PreviewText__color--light-900 {
	color: var(--neutral-light-900);
}

.PreviewText__color--black {
	color: black;
}

.PreviewText__color--dark-50 {
	color: var(--neutral-dark-50);
}

.PreviewText__color--dark-150 {
	color: var(--neutral-dark-150);
}

.PreviewText__color--dark-300 {
	color: var(--neutral-dark-300);
}

.PreviewText__color--dark-450 {
	color: var(--neutral-dark-450);
}

.PreviewText__color--dark-600 {
	color: var(--neutral-dark-600);
}

.PreviewText__color--dark-750 {
	color: var(--neutral-dark-750);
}

.PreviewText__color--dark-900 {
	color: var(--neutral-dark-900);
}
